import * as React from "react";
import { logger } from "bernie-core";
import {
  StorefrontWizardRegionProps,
  StorefrontWizardRegionTemplateComponentConfig,
  WizardComponentTemplateComponentConfig,
} from "src/components/shared/StorefrontWizard/typings";
import { LobQueryRouter } from "src/components/shared/StorefrontWizard/LobQueryRouter";
import { inject } from "mobx-react";
import { CustomerNotifications } from "components/shared/CustomerNotifications";
import {
  getWizardLOBTemplateComponents,
  getNonWizardLOBTemplateComponents,
} from "src/components/shared/StorefrontWizard/StorefrontWizardUtils";
import {
  CollapsableContainer,
  CollapsableComponentName,
} from "components/shared/StorefrontWizard/CollapsableContainer/CollapsableContainer";
import { LobFormWithTabs } from "src/components/shared/StorefrontWizard/LobFormWithTabs/LobFormWithTabs";
import { UitkSpacing } from "@egds/react-core/spacing";
import { ExtendedTemplateComponent } from "src/stores/ExperienceTemplateStore/typings";
import { RegionChildrenList } from "src/utils/RegionUtils";

@logger("StorefrontWizardRegion")
@inject("wizardState", "context")
export class StorefrontWizardRegion extends React.Component<StorefrontWizardRegionProps> {
  public render() {
    const { templateComponent, context, blossomComponent, wizardState } = this.props;

    if (!blossomComponent || !templateComponent.children || templateComponent.children.length < 1) {
      return null;
    }

    //default to false if device information is not available
    wizardState.globalWizardState.setIsDesktop(context.deviceInformation);

    const options = templateComponent.config as StorefrontWizardRegionTemplateComponentConfig;
    if (options.defaultLOB) {
      wizardState.globalWizardState.config.navigation.defaultLob = options.defaultLOB;
      wizardState.globalWizardState.config.navigation.activeLob = options.defaultLOB;
    }

    const wizardFormChildren = getWizardLOBTemplateComponents(templateComponent.children);
    const otherFormChildren = getNonWizardLOBTemplateComponents(
      templateComponent.children as ExtendedTemplateComponent[]
    );

    const isSingleLOB = wizardFormChildren.length === 1;

    const isStorefrontPage = context.searchContext.pageType.indexOf("Storefront") > -1;

    wizardState.globalWizardState.config.collapsed =
      options.collapsed ?? wizardState.globalWizardState.config.collapsed;

    const { collapsed } = wizardState.globalWizardState.config;

    return (
      <div className="StorefrontWizardRegion">
        <div className="SimpleContainer" data-testid="main-region">
          <div className="wizard-padding">
            <CollapsableContainer
              componentName={CollapsableComponentName.STOREFRONT_WIZARD_REGION}
              isCollapsedWizardEnabled={collapsed}
            >
              <UitkSpacing padding={{ block: "three" }}>
                <div data-testid="storefront-region">
                  <LobQueryRouter lobsTemplateComponent={wizardFormChildren} />
                  {isStorefrontPage && (
                    <UitkSpacing margin={{ block: "six" }}>
                      <CustomerNotifications location="AFTER_HEADER" />
                    </UitkSpacing>
                  )}
                  {isSingleLOB ? (
                    <UitkSpacing padding={{ small: { inline: "six" }, medium: { inline: "unset" } }}>
                      <div>
                        <RegionChildrenList
                          templateComponents={wizardFormChildren}
                          blossomComponent={blossomComponent}
                        />
                      </div>
                    </UitkSpacing>
                  ) : (
                    <LobFormWithTabs
                      context={context}
                      templateComponents={wizardFormChildren}
                      blossomComponent={blossomComponent}
                      wizardFormSpacing={{ padding: { small: { inline: "six" }, medium: { inline: "unset" } } }}
                    />
                  )}
                </div>
              </UitkSpacing>
              {otherFormChildren.map((child) => (
                <div
                  key={`parent-${child.metadata.id}`}
                  className={
                    child.config && (child.config as WizardComponentTemplateComponentConfig).horizontalCenter === false
                      ? "prevent-justify-center"
                      : ""
                  }
                >
                  <RegionChildrenList templateComponents={[child]} blossomComponent={blossomComponent} />
                </div>
              ))}
            </CollapsableContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default StorefrontWizardRegion;
